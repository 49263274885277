<template>
  <div class="apply">
    <div class="container">
      <div class="container-item" v-for="(item,key) in detailData[fMessage]" :key="key">
        <h2 v-html="item.title"></h2>
        <p v-for="(item2,key2) in item.detail" :key="key2" v-html="item2">
         
        </p>
        
        <p>
         <img v-for="(item3,key3) in item.img" :key="key3" :src="item3" alt="" />
        </p>
      </div>
      <!-- <div class="container-item">
        <h2>瑞典电信运营商</h2>
        <p>
          应用：于瑞典精品店内橱窗设置裸眼3D显示器展示销售精品，以求提升广告效益及客户关注度。
        </p>
        <p>产品：55寸定制高亮度2台 4k裸眼3D显示器</p>
        <p>
          <img src="@/assets/img/apply/20190808160521514.jpg" alt="" /><img
            src="@/assets/img/apply/20190808160524233.jpg"
            alt=""
          /><img src="@/assets/img/apply/20190808160522967.jpg" alt="" />
        </p>
      </div>
      <div class="container-item">
        <h2>瑞典电信运营商</h2>
        <p>
          应用：于瑞典精品店内橱窗设置裸眼3D显示器展示销售精品，以求提升广告效益及客户关注度。
        </p>
        <p>产品：55寸定制高亮度2台 4k裸眼3D显示器</p>
        <p>
          <img src="@/assets/img/apply/20190808160521514.jpg" alt="" /><img
            src="@/assets/img/apply/20190808160524233.jpg"
            alt=""
          /><img src="@/assets/img/apply/20190808160522967.jpg" alt="" />
        </p>
      </div>
      <div class="container-item">
        <h2>瑞典电信运营商</h2>
        <p>
          应用：于瑞典精品店内橱窗设置裸眼3D显示器展示销售精品，以求提升广告效益及客户关注度。
        </p>
        <p>产品：55寸定制高亮度2台 4k裸眼3D显示器</p>
        <p>
          <img src="@/assets/img/apply/20190808160521514.jpg" alt="" /><img
            src="@/assets/img/apply/20190808160524233.jpg"
            alt=""
          /><img src="@/assets/img/apply/20190808160522967.jpg" alt="" />
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
    name:'apply',
    props:{

    },
    data(){
        return {
            detailData:{
                1:[
                    {title:"Swedish telecom operator",detail:["Application: set up naked eye 3D display in the window of Swedish boutique to display sales boutiques, so as to improve advertising efficiency and customer attention.","Product: 55 inch customized high brightness 2 4K naked eye 3D displays"],img:[require("../../assets/img/apply/20190808160522967.jpg")]},
                   ],
                2:[
                     {title:"Naked eye 3D splicing screen settled in Fuzhou Lin Zexu Memorial Hall",detail:["Recently, the Lin Zexu Memorial Hall in Fuzhou started the digital upgrading project and added a complete set of naked eye 3D splicing display screen. By playing real three-dimensional 3D animation, the audience can be more immersed in the historical situation and appreciate the patriotic feelings of heroes!"],img:[require("../../assets/img/apply/20190819160815408.jpg")]}
                ]
            },
            fMessage:this.$route.query.id || 1 ,
        }
    },
    mounted(){
        
    },
      watch:{
    $route(to,from){
      //监听路由，当路由改变时，改变选项
      this.fMessage = to.query.id
    }
}
};
</script>

<style lang="less" scoped>
.apply {
  .container {
    text-align: center;
    // max-width: 1400px;
    width: auto;
    .container-item {
        margin-bottom: 48px;
        padding: 0 15px;
    }
    p {
      line-height: 28px;
      color: #666;
      margin: 0 0 5px 0;
      white-space: normal;
      img {
        border: none;
        max-width: 100%;
        vertical-align: middle;
      }
    }
  }
}
</style>>

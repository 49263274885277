<template>
  <div class="about">
    <header-img :bgImg="require('@/assets/img/apply/Banner002187-ban5.jpg')"></header-img>
    <div class="about-box">
      <item-contorl :item="item" :titleActive="titleActive"  :toRoute="thisRoute"></item-contorl>
      <div class="detail-box">
       <!-- <apply :fMessage="titleActive"></apply> -->
       <router-view></router-view>
      </div>
      
    </div>
  </div>
</template>
<script>
import headerImg from "@/components/CommonConponent/headerImg.vue";
// import aboutCompany from "@/components/apply/apply.vue";
import Apply from '../components/apply/apply.vue';
import ItemContorl from "../components/CommonConponent/itemContorl.vue";

export default {
  components: {
    Apply,headerImg,ItemContorl
  },
  data() {
    return {
      titleActive: this.$route.query.id || "1" ,
      thisRoute:'/apply/index',
      item: {
        title: "References",
        list: [
          { index: "1", detail: "Glasses-Free 3D LCD" },
          { index: "2", detail: "3D Video Wall" },
        ],
      },
    };
  },
  methods: {
    activeClick(e) {
      this.titleActive = e.target.parentElement.dataset.index || e.target.dataset.index; 
    },
  },
    watch:{
    $route(to,from){
      //监听路由，当路由改变时，改变选项
      this.titleActive = to.query.id
    }
}
};
</script>
<style lang="less" scoped>
.about-box {
  min-height: 300px;
  .title {
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    background: #f9f9f9;
    line-height: 50px;
    height: 52px;
    box-sizing: border-box;
    .title-box {
      padding: 0 2%;
      max-width: 1400px;

      display: flex;
      .product-title {
        width: 180px;
        line-height: 80px;
        background: #0068b7;
        font-size: 24px;
        color: #fff;
        text-align: center;
        position: relative;
        z-index: 1;
        margin-top: -30px;
        border-radius: 50px 0 0px 0;
      }
    }
    .title-flex {
      display: flex;
      margin-left: 20px;
      > div {
        display: block;
        padding: 0 12px;
        position: relative;
        height: 50px;
        color: #333;
        text-decoration: none;
        cursor: pointer;
      }
      span {
        display: block;
        width: 100%;
        height: 100%;
      }
      .active {
        span::after {
          //下划线
          background: #0068b7;
          position: absolute;
          left: 0;
          width: 100%;
          height: 3px;
          content: "";
          bottom: -1px;
        }
        &::after {
          // background: #0068b7;
          //倒三角
          display: block;
          width: 0;
          height: 0;
          margin: 0 auto;
          content: "";
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 10px solid #0068b7;
        }
      }
    }
  }
  .detail-box {
    position: relative;
    z-index: 1;
    padding: 6% 0 3% 0;
    height: min-543px;
    overflow: hidden;
   
  }
}
</style>
